import "../../styles/pages/legal.scss";

import Layout from "../../components/Layout";
import React from "react";
import SEO from "../../components/SEO";

/**
 * @param props
 * @param props.pageContext
 * @param props.pageContext.page
 */
function Default({ pageContext: data }) {
  const content = data?.pageTemplateLegal?.content;
  const { seo } = data;
  const { title, metaDesc } = seo;
  return (
    <Layout gradient={true} theme="dark">
      <SEO title={title} description={metaDesc} />

      <article className="Legal">
        <div className="Legal-contentContainer">
          <h1 className="Legal-title">{data?.title}</h1>
          <div
            className="Legal-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </article>
    </Layout>
  );
}

export default Default;
